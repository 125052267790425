import React from "react";

import classes from "../../../assets/stylesheets/Dots.module.scss";

function LoadingDots() {
	return (
		<span className={classes.Spinner}>
			<span className={classes.Bounce1}></span>
			<span className={classes.Bounce2}></span>
			<span></span>
		</span>
	);
}

export default LoadingDots;
