import React from "react";
import PropTypes from "prop-types";

import numberTruncation from "../../utils/numberTruncation";
import strTruncation from "../../utils/strTruncation";

const ArticleInfo = ({
	children,
	styleClassName,
	articleDir,
	show,
	articleDate,
	isAuthorImg,
	authorName,
	authorImg,
	articlePath,
	articleViews,
	authorBio,
	authorLink,
}) => {
	return (
		<div>
			<div className={styleClassName.ArticleItemInfo} dir={articleDir}>
				{children}
				<div
					className={`d-flex justify-content-start position-relative ${
						show ? "align-items-center" : styleClassName.InfoWrapper
					}`}
				>
					{authorImg ? (
						<a
							href={authorLink}
							className={styleClassName.Author_profile}
							style={{
								backgroundImage: `url("${authorImg}")`,
							}}
						></a>
					) : null}

					<div className={styleClassName.Content}>
						<a href={authorLink} className={styleClassName.Author_title}>
							{authorName}
						</a>

						<span className={styleClassName.Author_badge}>
							{strTruncation({ str: authorBio, trunLimit: "60" })}
						</span>

						{show ? (
							<div className={styleClassName.ArticleTime}>{articleDate}</div>
						) : null}
					</div>
				</div>
			</div>

			{!show ? (
				<div className="d-flex justify-content-between py-3">
					<div className={styleClassName.ArticleTime}>{articleDate}</div>
					<div className={styleClassName.ArticleViews}>
						<i className="far fa-eye"></i>
						<span>{numberTruncation(articleViews)}</span>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default ArticleInfo;

ArticleInfo.propTypes = {
	styleClassName: PropTypes.object,
	show: PropTypes.bool,
	isAuthorImg: PropTypes.bool,
	articleDir: PropTypes.string,
	articleDate: PropTypes.string,
	authorName: PropTypes.string,
	articleTitle: PropTypes.string,
	authorImg: PropTypes.string,
	articlePath: PropTypes.string,
	articleViews: PropTypes.number,
};
