import React from "react";
import PropTypes from "prop-types";

const ArticleHero = ({ children, styleClassName, articleDir }) => {
	let content = (
		<div className={styleClassName.ArticleImgContainer} dir={articleDir}>
			{children}
		</div>
	);
	return content;
};

export default ArticleHero;

ArticleHero.propTypes = {
	styleClassName: PropTypes.object,
	articleDir: PropTypes.string,
	articleTitle: PropTypes.string,
	isArticleText: PropTypes.bool,
};
