import React, { useState } from "react";

import classes from "../../../assets/stylesheets/SearchBar.module.scss";

function SearchBar() {
	const [searchBarState, changeSearchBarState] = useState("");

	function searchBarHandler(e) {
		changeSearchBarState(e.target.value);
	}

	return (
		<form className={`${classes.SearchBarForm} mb-5`} action="/search">
			<div className={classes.SearchBarInputWrapper}>
				<input
					className={classes.SearchBar}
					name="search_criteria"
					type="text"
					placeholder="Search Articles"
					aria-label="Search"
					autoComplete="off"
					onChange={searchBarHandler}
					value={searchBarState}
				/>
				<button type="submit">
					<i className="fas fa-search"></i>
				</button>
			</div>
		</form>
	);
}

export default SearchBar;
