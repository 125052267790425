import React from "react";

const ReportOption = ({ option, id, disableOtherOptionHandler }) => {
	return (
		<div className="form-check mb-2">
			<input
				className="form-check-input"
				type="radio"
				name="reportOption"
				id={`option_${id}`}
				onChange={disableOtherOptionHandler}
				value={`option_${id}`}
				defaultChecked={!id}
			/>
			<label className="form-check-label" htmlFor={`option_${id}`}>
				{option}
			</label>
		</div>
	);
};

export default ReportOption;
