function extractVideoId(url) {
	let videoid = url.match(
		/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/
	);
	if (videoid != null) {
		return videoid[1];
	} else {
		return null;
	}
}

export default extractVideoId;
