import React, { useState } from "react";
import cookies from "js-cookie";

import Backdrop from "../ui/Backdrop";

import classes from "../../../assets/stylesheets/Consent_Modal";
import SettingModalItem from "./SettingModalItem";

const Consent_Modal = () => {
	const [isHiddenModal, changeIsHiddenModalStateHandler] = useState(
		cookies.get("allowCookies") !== undefined
	);
	const [isHiddenBackdropState, changeIsHiddenBackdropStateHandler] = useState(
		cookies.get("allowCookies") !== undefined
	);

	const [
		isHiddenSettingsModalState,
		changeIsHiddenSettingsModalState,
	] = useState(true);

	const [isCheckedPreferencesState, changeIsCheckedPreferencesState] = useState(
		true
	);

	const [isCheckedStatisticsState, changeIsCheckedStatisticsState] = useState(
		true
	);

	const [isCheckedMarketingState, changeIsCheckedMarketingState] = useState(
		true
	);

	const setCookie = ({
		isCheckedPreferencesState,
		isCheckedStatisticsState,
		isCheckedMarketingState,
	}) => {
		cookies.set(
			"allowCookies",
			`necessary=true&preferences=${isCheckedPreferencesState}&statistics=${isCheckedStatisticsState}&marketing=${isCheckedMarketingState}`,
			{ expires: 365, path: "/", secure: true }
		);
	};
	const AgreeHandler = () => {
		changeIsHiddenBackdropStateHandler(true);
		changeIsHiddenModalStateHandler(true);
		setCookie({
			isCheckedPreferencesState: true,
			isCheckedStatisticsState: true,
			isCheckedMarketingState: true,
		});
	};

	const SettingsHandler = () => {
		changeIsHiddenModalStateHandler(true);
		changeIsHiddenSettingsModalState(false);
	};

	const personalizeSettingsHandler = (e) => {
		e.preventDefault();
		setCookie({
			isCheckedPreferencesState,
			isCheckedStatisticsState,
			isCheckedMarketingState,
		});
		changeIsHiddenSettingsModalState(true);
		changeIsHiddenBackdropStateHandler(true);
	};

	return (
		<>
			<Backdrop isHidden={isHiddenBackdropState} />
			{isHiddenModal && !isHiddenSettingsModalState ? (
				<div className={classes.Settings_Modal}>
					<div className="h3 mb-4">Personalize your experience</div>
					<form onSubmit={personalizeSettingsHandler}>
						<div className="form-check mb-3">
							<input
								type="checkbox"
								className="form-check-input"
								id="NecessaryCheck"
								disabled={true}
								checked={true}
							/>
							<label className="form-check-label" htmlFor="NecessaryCheck">
								Necessary
							</label>
						</div>
						<SettingModalItem
							isChecked={isCheckedPreferencesState}
							changeIsChecked={changeIsCheckedPreferencesState}
							inputId="preferencesCheck"
							labelVal="Preferences"
						/>
						<SettingModalItem
							isChecked={isCheckedStatisticsState}
							changeIsChecked={changeIsCheckedStatisticsState}
							inputId="statisticsCheck"
							labelVal="Statistics"
						/>
						<SettingModalItem
							isChecked={isCheckedMarketingState}
							changeIsChecked={changeIsCheckedMarketingState}
							inputId="marketingCheck"
							labelVal="Marketing"
						/>
						<button className="btn-large btn btn-block" type="submit">
							save
						</button>
					</form>
				</div>
			) : null}
			{!isHiddenModal ? (
				<div className={classes.Consent_Modal}>
					<div className="container">
						<div className="row">
							<div className="col-12 col-lg-7">
								<div className={classes.content}>
									<h3 className={classes.content_header}>
										We value your privacy
									</h3>
									<div>
										This website uses cookies to provide you with all features
										as designed. By using this website, and/or clicking the
										"Consent" button, you accept our Privacy Policy and our use
										of cookies. Know more on our{" "}
										<a href={"https://www.robonero.com/privacy_policy"}>
											Privacy Policy
										</a>
										page
									</div>
								</div>
							</div>
							<div className="col-12 col-lg-4 offset-lg-1">
								<div className={classes.Btns}>
									<button
										className="btn-large btn btn-block"
										onClick={AgreeHandler}
									>
										Consent
									</button>
									<button
										className="btn-large btn btn-block"
										onClick={SettingsHandler}
									>
										Settings
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};

export default Consent_Modal;
