import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import classes from "../../../assets/stylesheets/Navbar.module.scss";

import Brand from "./Brand";
import ButtonsControl from "./ButtonsControl";
import LanguageWrapper from "./LanguageWrapper";
import NavList from "./NavList";

const Navbar = ({
	props: {
		dir,
		reverse,
		home_txt,
		login_txt,
		logout_txt,
		signup_txt,
		edit_profile_txt,
		write_now_txt,
		contact_us_txt,
		link_to_other_lang_first_link,
		link_to_other_lang_first_lang,
		link_to_other_lang_last_link,
		link_to_other_lang_last_lang,
		user_signed_in,
		root_p,
		home_path,
		edit_user_registration_path,
		new_user_session_path,
		destroy_user_session_path,
		new_user_registration_path,
		write_article_authorized_user,
		write_article_path,
		contact_us_path,
		write_with_us_txt,
		authenticityToken,
		navbarLgRef,
		navbarSmRef,
		isDarkMode,
	} = props,
}) => {
	const [isDesktopState, changeIsDesktopState] = useState(
		window.innerWidth > 991.99
	);

	const updateMedia = () => {
		if (window.innerWidth > 991.99) {
			changeIsDesktopState(true);
		} else {
			changeIsDesktopState(false);
		}
	};

	useEffect(() => {
		window.addEventListener("resize", updateMedia);
		return () => window.removeEventListener("resize", updateMedia);
	});

	return (
		<nav
			className={`${classes.navbar} ${reverse ? classes.navbarReverse : ""}
	        navbar
	        navbar-expand-lg
	        navbar-light
	        py-4`}
		>
			<div className="container">
				<Brand root_p={root_p} isDarkMode={isDarkMode} />
				<LanguageWrapper
					dir={dir}
					small
					first_link={link_to_other_lang_first_link}
					first_lang={link_to_other_lang_first_lang}
					last_link={link_to_other_lang_last_link}
					last_lang={link_to_other_lang_last_lang}
				/>

				<div
					className={`${classes.collapse} ${
						dir === "rtl" ? classes.rtl_collapse : ""
					}  justify-content-lg-end`}
					id="navbarNavDropdown"
				>
					<NavList
						dir={dir}
						styleClasses={classes}
						isDesktopState={isDesktopState}
						home_path={home_path}
						home_txt={home_txt}
						edit_profile_txt={edit_profile_txt}
						write_now_txt={write_now_txt}
						contact_us_txt={contact_us_txt}
						edit_user_registration_path={edit_user_registration_path}
						user_signed_in={user_signed_in}
						write_article_authorized_user={write_article_authorized_user}
						write_article_path={write_article_path}
						contact_us_path={contact_us_path}
						root_path={root_p}
						new_user_session_path={new_user_session_path}
						login_txt={login_txt}
						new_user_registration_path={new_user_registration_path}
						signup_txt={signup_txt}
						destroy_user_session_path={destroy_user_session_path}
						logout_txt={logout_txt}
						write_with_us_txt={write_with_us_txt}
						authenticityToken={authenticityToken}
						navbarSmRef={navbarSmRef}
					/>

					<LanguageWrapper
						dir={dir}
						first_link={link_to_other_lang_first_link}
						first_lang={link_to_other_lang_first_lang}
						last_link={link_to_other_lang_last_link}
						last_lang={link_to_other_lang_last_lang}
					/>

					{isDesktopState ? (
						<ButtonsControl
							navbarLgRef={navbarLgRef}
							root_path={root_p}
							user_signed_in={user_signed_in}
							new_user_session_path={new_user_session_path}
							login_txt={login_txt}
							new_user_registration_path={new_user_registration_path}
							signup_txt={signup_txt}
							destroy_user_session_path={destroy_user_session_path}
							logout_txt={logout_txt}
							authenticityToken={authenticityToken}
						/>
					) : null}
				</div>
			</div>
		</nav>
	);
};

export default Navbar;

Navbar.propTypes = {
	dir: PropTypes.string,
	home_txt: PropTypes.string,
	login_txt: PropTypes.string,
	logout_txt: PropTypes.string,
	signup_txt: PropTypes.string,
	edit_profile_txt: PropTypes.string,
	write_now_txt: PropTypes.string,
	contact_us_txt: PropTypes.string,
	link_to_other_lang_first_link: PropTypes.string,
	link_to_other_lang_first_lang: PropTypes.string,
	link_to_other_lang_last_link: PropTypes.string,
	link_to_other_lang_last_lang: PropTypes.string,
	user_signed_in: PropTypes.bool,
	root_p: PropTypes.string,
	home_path: PropTypes.string,
	edit_user_registration_path: PropTypes.string,
	new_user_session_path: PropTypes.string,
	destroy_user_session_path: PropTypes.string,
	new_user_registration_path: PropTypes.string,
	write_article_authorized_user: PropTypes.bool,
	write_article_path: PropTypes.string,
	contact_us_path: PropTypes.string,
};
