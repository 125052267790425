import React from "react";
import PropTypes from "prop-types";

import classes from "../../../assets/stylesheets/SubscribeForm.module.scss";

const SubscribeForm = ({ subscribe_txt, email_txt }) => {
	return (
		<form
			className={classes.subscribeForm}
			onSubmit={(event) => {
				event.preventDefault();
				location.assign("http://eepurl.com/helJt1");
			}}
		>
			<button type="submit" className="btn mb-2 w-100">
				{subscribe_txt}
			</button>
		</form>
	);
};

export default SubscribeForm;

SubscribeForm.propTypes = {
	email_txt: PropTypes.string,
	subscribe_txt: PropTypes.string,
};
