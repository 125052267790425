import React from "react";
import Message from "./Message";

function Messages({ listState }) {
	const output = listState.map((msg) => {
		return <Message key={msg.id} msg={msg} />;
	});
	return output;
}

export default Messages;
