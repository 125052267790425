import React from "react";
import PropTypes from "prop-types";

import classes from "../../../assets/stylesheets/Navbar.module.scss";

import translate from "../../../assets/images/translate.svg";

import Dropdown from "./Dropdown";

const LanguageWrapper = ({
	dir,
	small,
	first_link,
	first_lang,
	last_link,
	last_lang,
}) => {
	return (
		<div
			className={`
            ${classes.lang_wrapper}
            ${dir === "rtl" ? classes.rtl_lang_wrapper : ""}
            position-relative
            ${small ? "ml-auto mr-lg-auto d-lg-none" : "d-none d-lg-block"}
        `}
		>
			<div className={classes.vertical}></div>
			<Dropdown styleClasses={classes.dropdown}>
				<Dropdown.Btn>
					<img height="25" width="25" src={translate} alt="translate" />
				</Dropdown.Btn>
				<Dropdown.DropdownMenu
					styleClasses={classes.dropdown_menu}
					dirClasses={
						dir === "ltr" ? ["dropdown-menu-left"] : ["dropdown-menu-right"]
					}
				>
					<a className="dropdown-item my-2 py-2" href={first_link}>
						{first_lang}
					</a>
					<a className="dropdown-item my-2 py-2" href={last_link}>
						{last_lang}
					</a>
				</Dropdown.DropdownMenu>
			</Dropdown>
		</div>
	);
};

export default LanguageWrapper;

LanguageWrapper.propTypes = {
	dir: PropTypes.string,
	small: PropTypes.bool,
	first_link: PropTypes.string,
	first_lang: PropTypes.string,
	last_link: PropTypes.string,
	last_lang: PropTypes.string,
};
