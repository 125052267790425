import React from "react";

const ArticleHzLine = () => {
	return (
		<hr
			style={{
				margin: "0",
				borderTop: "1px solid #f5f5f5",
			}}
		/>
	);
};

export default ArticleHzLine;
