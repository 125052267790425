const strTruncation = ({
	str,
	trunLimit = 80,
	charRemoved = 3,
	symobl = "...",
}) => {
	let updatedTrun = trunLimit - charRemoved;
	return str.length > trunLimit ? str.substring(0, updatedTrun) + symobl : str;
};

export default strTruncation;
