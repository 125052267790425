import React, { useState, useEffect, useRef, useMemo } from "react";
import Prism from "prismjs";
import { v4 as uid } from "uuid";
import detectLanguage from "../../utils/detectLanguage";

import LoadingDots from "../ui/LoadingDots";

import classes from "../../../assets/stylesheets/Chatbot.module.scss";
import ChatbodyPlaceholder from "./ChatbodyPlaceholder";
import ChatbotForm from "./ChatbotForm";
import Messages from "./Messages";
import ErrorAleart from "../ui/ErrorAleart";

const Chatbot = () => {
	const prefix = "<st>";
	const bottomPointRef = useRef();
	const textAreaRef = useRef(null);
	const formRef = useRef(null);
	const [errorState, changeErrorState] = useState(false);
	const [errorMsgState, changeErrorMsgState] = useState(null);
	const [allowMessageState, changeAllowMessageState] = useState(true);
	const [listState, changeListState] = useState([]);
	const [messageState, changeMessageState] = useState("");
	const [isLoadingState, changeIsLoadingState] = useState(false);
	useEffect(() => {
		if (!allowMessageState && bottomPointRef.current) {
			bottomPointRef.current.scrollTop = bottomPointRef.current.scrollHeight;
		}
	}, [allowMessageState]);

	useEffect(() => {
		Prism.plugins.NormalizeWhitespace.setDefaults({
			"remove-trailing": true,
			"remove-indent": true,
			"left-trim": true,
			"right-trim": true,
		});
		Prism.highlightAll();
	}, [listState]);

	function handleCodeBlock(data) {
		const codeFlaggedData = data.replaceAll("<code>", `<code>${prefix}`);
		const splitedDataArr = codeFlaggedData.split(/<\/?code>/);
		const trimmedDataArr = splitedDataArr.filter((item) => item != "");
		const withCodeLangDataArr = trimmedDataArr.map((item) => {
			if (item.startsWith("<st>")) {
				let lang = detectLanguage(item);
				return {
					lang: lang,
					code: item,
				};
			}
			return item;
		});
		return withCodeLangDataArr;
	}

	function changeHandler(e) {
		if (e.target) {
			changeMessageState(e.target.value);
		}
	}

	function submitHandler(e) {
		e.preventDefault();
		changeErrorState(false);
		if (messageState && allowMessageState) {
			changeIsLoadingState(true);
			changeMessageState("");
			changeAllowMessageState(false);
			const message = {
				id: uid(),
				text: messageState,
				isResponse: false,
			};
			const updatedList = [...listState, message];
			changeListState(updatedList);
			fetch(`/chatbots/${messageState}`)
				.then((res) => {
					return res.json();
				})
				.then((data) => {
					const handledData = handleCodeBlock(data.text);
					const response = {
						id: uid(),
						text: handledData,
						isResponse: true,
					};
					const updatedListWithResponse = [...updatedList, response];
					changeListState(updatedListWithResponse);
					changeIsLoadingState(false);
					changeAllowMessageState(true);
				})

				.catch((err) => {
					changeErrorMsgState({ msg: "something went wrong" });
					changeErrorState(true);
					changeIsLoadingState(false);
				});
		}
	}

	function resizeTextArea() {
		textAreaRef.current.style.height = "auto";
		textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
	}

	useEffect(resizeTextArea, [messageState]);
	useEffect(() => {
		const listener = (e) => {
			if (e.keyCode == 13 && e.shiftKey == false) {
				e.preventDefault();
				e.stopPropagation();
				submitHandler(e);
			}
		};
		document.addEventListener("keydown", listener);
		return () => {
			document.removeEventListener("keydown", listener);
		};
	}, [messageState]);

	return (
		<section className={classes.Chatbot}>
			<ErrorAleart
				isError={errorState}
				errorMessage={
					errorMsgState ? errorMsgState.msg : "something went wrong"
				}
				changeIsError={changeErrorState}
			/>
			<div
				className={`${classes.Chatbody} ${
					!listState.length ? classes.EmptyChatbody : ""
				}`}
				ref={bottomPointRef}
			>
				<ChatbodyPlaceholder shown={!listState.length} />
				{useMemo(
					() => (
						<Messages listState={listState} />
					),
					[listState]
				)}

				{isLoadingState ? (
					<div className={`${classes.Message} ${classes.Response}`}>
						<LoadingDots />
					</div>
				) : null}
			</div>
			<ChatbotForm
				submitHandler={submitHandler}
				messageState={messageState}
				isLoadingState={isLoadingState}
				changeHandler={changeHandler}
				textAreaRef={textAreaRef}
				formRef={formRef}
			/>
		</section>
	);
};

export default Chatbot;
