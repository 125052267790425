import React from "react";

import classes from "../../../assets/stylesheets/AnimateArticleControl.module.scss";

const ArticleControl = ({ children, styleClassName, hideControlsState }) => {
	return (
		<div
			className={`${styleClassName.ArticleItemControl} ${
				classes.AnimateArticleControl
			} ${hideControlsState ? classes.fade : classes.show}`}
		>
			{children}
		</div>
	);
};

export default ArticleControl;
