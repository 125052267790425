import React from "react";

import PropTypes from "prop-types";

import classes from "../../../assets/stylesheets/Footer.module.scss";

import FooterBg from "../../../assets/images/footer_bg.png";
import Volks from "../../../assets/images/volks.gif";
import Cyclist from "../../../assets/images/cyclist.gif";

import FooterItem from "./FooterItem";
import SubscribeForm from "./SubscribeForm";
import FooterList from "./FooterList";
import FooterListWithIcon from "./FooterListWithIcon";

const Footer = ({
	props: {
		dir,
		get_in_touch_txt,
		get_in_touch_msg_txt,
		terms_and_conditions_txt,
		support_policy_txt,
		team_solutions_txt,
		subscribe_txt,
		help_txt,
		terms_and_conditions_url,
		privacy_policy_url,
		contact_us_txt,
		contact_us_path,
		facebook_txt,
		linkedin_txt,
		youtube_txt,
		github_txt,
		discord_txt,
		instagram_txt,
		credits_txt,
		made_by_1_txt,
		made_by_2_txt,
		email_txt,
	},
}) => {
	return (
		<div className={classes.Footer}>
			<div className="position-relative">
				<div className="container">
					<div>
						<div className="row">
							<div
								className={`${
									dir === "rtl" ? "offset-lg-1" : ""
								} col-12 col-lg-4`}
							>
								<FooterItem header={get_in_touch_txt}>
									<p>{get_in_touch_msg_txt}</p>
									<SubscribeForm
										subscribe_txt={subscribe_txt}
										email_txt={email_txt}
									/>
								</FooterItem>
							</div>
							<div
								className={`${
									dir === "ltr" ? "offset-lg-1" : ""
								} col-12 col-lg-3`}
							>
								<FooterItem header={help_txt}>
									<FooterList
										data={[
											{
												txt: contact_us_txt,
												url: contact_us_path,
											},
											{
												txt: terms_and_conditions_txt,
												url: terms_and_conditions_url,
											},
											{
												txt: support_policy_txt,
												url: privacy_policy_url,
											},
										]}
									/>
								</FooterItem>
							</div>
							<div className="col-12 col-lg-4">
								<FooterItem header={team_solutions_txt}>
									<FooterListWithIcon
										dir={dir}
										facebook={facebook_txt}
										linkedin={linkedin_txt}
										youtube={youtube_txt}
										github={github_txt}
										discord={discord_txt}
										instagram={instagram_txt}
									/>
								</FooterItem>
							</div>
						</div>
					</div>
				</div>

				<div className={classes.footer_bg}>
					<img src={FooterBg} alt="footer_bg" draggable={false} />
					<div className={classes.footer_bg_one}>
						<img
							className="img-fluid"
							src={Volks}
							alt="volks"
							draggable={false}
						/>
					</div>
					<div className={classes.footer_bg_two}>
						<img
							className="img-fluid"
							src={Cyclist}
							alt="cyclist"
							draggable={false}
						/>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="d-flex flex-column flex-lg-row align-items-center justify-content-between my-2">
					<div className="text-center text-lg-left">
						<p className="mb-0">{credits_txt}</p>
					</div>
					<div className="text-lg-right text-center">
						<p className="mb-0">
							{made_by_1_txt}
							<i className="fas fa-heart text-danger"></i> {made_by_2_txt}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;

Footer.propTypes = {
	dir: PropTypes.string,
	terms_and_conditions_txt: PropTypes.string,
	documention_txt: PropTypes.string,
	support_policy_txt: PropTypes.string,
	team_solutions_txt: PropTypes.string,
	get_in_touch_txt: PropTypes.string,
	get_in_touch_msg_txt: PropTypes.string,
	subscribe_txt: PropTypes.string,
	help_txt: PropTypes.string,
	terms_and_conditions: PropTypes.string,
};
