import React from "react";
import PropTypes from "prop-types";

import classes from "../../../assets/stylesheets/Navbar.module.scss";

const ButtonsControl = ({
	root_path,
	user_signed_in,
	new_user_session_path,
	login_txt,
	new_user_registration_path,
	signup_txt,
	destroy_user_session_path,
	logout_txt,
	navbarLgRef,
}) => {
	const logout_handler = () => {
		$.ajax({
			url: destroy_user_session_path,
			type: "delete",
			success: () => {
				window.location.reload();
				window.location.href = root_path;
			},
		});
	};

	return (
		<div
			className={`${classes.btn_control} d-flex flex-column justify-content-center align-items-center flex-lg-row  align-items-lg-center`}
			ref={navbarLgRef}
		>
			{!user_signed_in ? (
				<>
					<a
						href={new_user_session_path}
						className={`${classes.login} btn btn-lg w-100`}
					>
						{login_txt}
					</a>

					<a href={new_user_registration_path} className="btn btn-lg w-100">
						{signup_txt}
					</a>
				</>
			) : (
				<button
					className={`${classes.logout} btn btn-lg w-100`}
					onClick={logout_handler}
				>
					{logout_txt}
				</button>
			)}
		</div>
	);
};

export default ButtonsControl;

ButtonsControl.propTypes = {
	user_signed_in: PropTypes.bool,
	new_user_session_path: PropTypes.string,
	login_txt: PropTypes.string,
	new_user_registration_path: PropTypes.string,
	signup_txt: PropTypes.string,
	destroy_user_session_path: PropTypes.string,
	logout_txt: PropTypes.string,
};
