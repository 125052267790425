import React from "react";
import PropTypes from "prop-types";

import classes from "../../../assets/stylesheets/FooterList.module.scss";

const FooterListWithIcon = ({
	dir,
	facebook,
	linkedin,
	youtube,
	github,
	discord,
	instagram,
}) => {
	return (
		<ul className={`${classes.footerList} nav flex`}>
			<div className={`${dir === "ltr" ? "mr-5" : "ml-5"}`}>
				<li className={`nav-item ${classes.withIcon}`}>
					<a className="nav-link" href="https://www.facebook.com/robone.net/">
						<span>
							<i className="fab fa-facebook"></i>
						</span>
						{facebook}
					</a>
				</li>
				<li className={`nav-item ${classes.withIcon}`}>
					<a
						className="nav-link"
						href="https://www.linkedin.com/company/robone-eg"
					>
						<span>
							<i className="fab fa-linkedin"></i>
						</span>
						{linkedin}
					</a>
				</li>
				<li className={`nav-item ${classes.withIcon}`}>
					<a
						className="nav-link"
						href="https://www.youtube.com/channel/UCDM3lxjF7iVH9aYkfchji-A"
					>
						<span>
							<i className="fab fa-youtube"></i>
						</span>
						{youtube}
					</a>
				</li>
			</div>

			<div>
				<li className={`nav-item ${classes.withIcon}`}>
					<a className="nav-link" href="https://github.com/RoboneEg">
						<span>
							<i className="fab fa-github"></i>
						</span>
						{github}
					</a>
				</li>
				<li className={`nav-item ${classes.withIcon}`}>
					<a className="nav-link" href="https://discord.gg/NWUQna74YX">
						<span>
							<i className="fab fa-discord"></i>
						</span>
						{discord}
					</a>
				</li>
				<li className={`nav-item ${classes.withIcon}`}>
					<a
						className="nav-link"
						href="https://www.instagram.com/roboneuronix/"
					>
						<span>
							<i className="fab fa-instagram"></i>
						</span>
						{instagram}
					</a>
				</li>
			</div>
		</ul>
	);
};

export default FooterListWithIcon;

FooterListWithIcon.propTypes = {
	dir: PropTypes.string,
};
