import React from "react";

import {
	EmailShareButton,
	FacebookShareButton,
	TwitterShareButton,
	LinkedinShareButton,
	EmailIcon,
	FacebookIcon,
	TwitterIcon,
	LinkedinIcon,
} from "react-share";

import numberTruncation from "../../utils/numberTruncation";

import Dropdown from "../navbar/Dropdown";
import ArticleControl from "../article_index/ArticleControl";

const ArticleShowControl = ({
	hideControlsState,
	ArticleControlShowClasses,
	likeHandler,
	isAuthor,
	likeState,
	likesNumberState,
	favHandler,
	favState,
	articleViews,
	dir,
	articleLocalLang,
	articleUrl,
	articleShowWrapperWidthState,
	articleShowWrapperWidthOptions,
	darkMode,
	articleShowFontSizeOptions,
	fontSizeState,
	updateFontSize,
	updateDarkMode,
	updateReaderWidth,
	shareUsingEmail,
	shareUsingFacebook,
	shareUsingLinkedin,
	shareUsingTwitter,
}) => {
	return (
		<ArticleControl
			hideControlsState={hideControlsState}
			styleClassName={ArticleControlShowClasses}
		>
			<button
				onClick={() => window.history.back()}
				className={`btn ${ArticleControlShowClasses.BackBtn}`}
			>
				<i className="fas fa-arrow-left"></i>
			</button>

			<button
				className={`btn ${ArticleControlShowClasses.LikesLink}  ${ArticleControlShowClasses.controlItem}`}
				onClick={likeHandler}
				disabled={isAuthor}
			>
				<i className={`fas fa-heart ${likeState ? "text-danger" : ""}`}></i>

				<div
					className={`text-center ${ArticleControlShowClasses.LikesCounter}`}
				>
					{isNaN(likesNumberState)
						? likesNumberState
						: likesNumberState > 0
						? numberTruncation(likesNumberState)
						: null}
				</div>
			</button>

			<Dropdown styleClasses={ArticleControlShowClasses.dropdown}>
				<Dropdown.Btn
					styleClasses={`${ArticleControlShowClasses.controlItem} `}
				>
					<i className="fas fa-cog"></i>
				</Dropdown.Btn>
				<Dropdown.DropdownMenu
					styleClasses={ArticleControlShowClasses.dropdown_menu}
					dir={dir}
				>
					<div className="p-2" style={{ width: "300px" }}>
						<div className="my-3">
							<div className="text-center mb-3">Font Size</div>
							<div className="d-flex justify-content-center align-items-stretch">
								<button
									className={`btn p-2 mx-2 ${
										ArticleControlShowClasses.fontAdjustBtn
									}
                                    ${
																			fontSizeState ===
																			articleShowFontSizeOptions.small
																				? ArticleControlShowClasses.Active
																				: ""
																		}`}
									onClick={() =>
										updateFontSize(articleShowFontSizeOptions.small)
									}
								>
									<i className="fas fa-font fa-sm"></i>
									<p className="mb-0">Small</p>
								</button>
								<button
									className={`btn p-2 mx-2 ${
										ArticleControlShowClasses.fontAdjustBtn
									} ${
										fontSizeState === articleShowFontSizeOptions.medium
											? ArticleControlShowClasses.Active
											: ""
									}`}
									onClick={() =>
										updateFontSize(articleShowFontSizeOptions.medium)
									}
								>
									<i className="fas fa-font fa-lg"></i>
									<p className="mb-0">Medium</p>
								</button>
								<button
									className={`btn p-2 mx-2 ${
										ArticleControlShowClasses.fontAdjustBtn
									} ${
										fontSizeState === articleShowFontSizeOptions.large
											? ArticleControlShowClasses.Active
											: ""
									}`}
									onClick={() =>
										updateFontSize(articleShowFontSizeOptions.large)
									}
								>
									<i className="fas fa-font fa-2x"></i>
									<p className="mb-0">Large</p>
								</button>
							</div>
						</div>
						<div className="mb-3">
							<div className="text-center mb-3">Toggle Lights </div>
							<div className="d-flex justify-content-center align-items-stretch">
								<button
									className={`btn p-2 mx-2 ${
										ArticleControlShowClasses.fontAdjustBtn
									} ${darkMode.value ? ArticleControlShowClasses.Active : ""}`}
									onClick={() => updateDarkMode(true)}
								>
									<i className="fas fa-moon"></i>
									<p className="mb-0">Dark Mode</p>
								</button>

								<button
									className={`btn p-2 mx-2 ${
										ArticleControlShowClasses.fontAdjustBtn
									} ${!darkMode.value ? ArticleControlShowClasses.Active : ""}`}
									onClick={() => updateDarkMode(false)}
								>
									<i className="fas fa-sun"></i>
									<p className="mb-0">Light Mode</p>
								</button>
							</div>
						</div>
						<div className="mb-3">
							<div className="text-center mb-3">Reader Width</div>
							<div className="d-flex justify-content-center align-items-stretch">
								<button
									className={`btn p-2 mx-2 ${
										ArticleControlShowClasses.fontAdjustBtn
									}
                                    ${
																			articleShowWrapperWidthState ===
																			articleShowWrapperWidthOptions.narrow
																				? ArticleControlShowClasses.Active
																				: ""
																		}`}
									onClick={() =>
										updateReaderWidth(articleShowWrapperWidthOptions.narrow)
									}
								>
									<i className="fas fa-align-justify fa-sm"></i>
									<p className="mb-0">Narrow</p>
								</button>
								<button
									className={`btn p-2 mx-2 ${
										ArticleControlShowClasses.fontAdjustBtn
									} ${
										articleShowWrapperWidthState ===
										articleShowWrapperWidthOptions.normal
											? ArticleControlShowClasses.Active
											: ""
									}`}
									onClick={() =>
										updateReaderWidth(articleShowWrapperWidthOptions.normal)
									}
								>
									<i className="fas fa-align-justify fa-lg"></i>
									<p className="mb-0">Normal</p>
								</button>
								<button
									className={`btn p-2 mx-2 ${
										ArticleControlShowClasses.fontAdjustBtn
									} ${
										articleShowWrapperWidthState ===
										articleShowWrapperWidthOptions.wide
											? ArticleControlShowClasses.Active
											: ""
									}`}
									onClick={() =>
										updateReaderWidth(articleShowWrapperWidthOptions.wide)
									}
								>
									<i className="fas fa-align-justify fa-2x"></i>
									<p className="mb-0">Wide</p>
								</button>
							</div>
						</div>
					</div>
				</Dropdown.DropdownMenu>
			</Dropdown>

			{!isAuthor ? (
				<button
					className={`btn ${ArticleControlShowClasses.controlItem}`}
					onClick={favHandler}
				>
					<i
						className={`fas fa-bookmark ${favState ? "text-warning" : ""}`}
					></i>
				</button>
			) : null}

			<div
				className={`${ArticleControlShowClasses.ViewsLink} ${ArticleControlShowClasses.controlItem}`}
			>
				<i className="far fa-eye"></i>
				<div className="text-center">{numberTruncation(articleViews)}</div>
			</div>

			<Dropdown styleClasses={ArticleControlShowClasses.dropdown}>
				<Dropdown.Btn styleClasses={ArticleControlShowClasses.controlItem}>
					<i className="fas fa-share-alt"></i>
				</Dropdown.Btn>
				<Dropdown.DropdownMenu
					styleClasses={ArticleControlShowClasses.dropdown_menu}
					dir={dir}
				>
					<div className={`list-group ${ArticleControlShowClasses.listGroup}`}>
						<FacebookShareButton
							quote={articleLocalLang}
							hashtag="Robone"
							className="list-group-item list-group-item-action"
							url={articleUrl}
						>
							<FacebookIcon />
							{shareUsingFacebook}
						</FacebookShareButton>
						<TwitterShareButton
							title={articleLocalLang}
							hashtags="Robone"
							className="list-group-item list-group-item-action"
							url={articleUrl}
						>
							<TwitterIcon />
							{shareUsingTwitter}
						</TwitterShareButton>
						<LinkedinShareButton
							title={articleLocalLang}
							className="list-group-item list-group-item-action"
							url={articleUrl}
						>
							<LinkedinIcon />
							{shareUsingLinkedin}
						</LinkedinShareButton>
						<EmailShareButton
							subject={articleLocalLang}
							className="list-group-item list-group-item-action"
							url={articleUrl}
						>
							<EmailIcon />
							{shareUsingEmail}
						</EmailShareButton>
					</div>
				</Dropdown.DropdownMenu>
			</Dropdown>

			<button
				type="button"
				className={`${ArticleControlShowClasses.controlItem} btn`}
				data-toggle="modal"
				data-target="#ReportModal"
			>
				<i className="far fa-flag" style={{ color: "#c21e56" }}></i>
			</button>
		</ArticleControl>
	);
};

export default ArticleShowControl;
