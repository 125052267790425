import React from "react";

import classes from "../../../assets/stylesheets/Chatbot.module.scss";
import ChatCodeBlock from "./ChatCodeBlock";

function Message({ msg }) {
	let output;
	if (Array.isArray(msg.text)) {
		output = msg.text.map((item, i) => {
			if (typeof item === "object") {
				const newItem = item.code.substring(
					item.code.lastIndexOf("<st>") + "<st>".length,
					item.code.length
				);
				return <ChatCodeBlock key={i} lang={item.lang} code={newItem} />;
			} else {
				return <div key={i}>{item}</div>;
			}
		});
	} else {
		output = <div>{msg.text}</div>;
	}

	return (
		<div
			className={`${classes.Message} ${
				msg.isResponse ? classes.Response : classes.Question
			}`}
		>
			{output}
		</div>
	);
}
export default Message;
