import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import Logo_robot from "../../../assets/images/Logo_robot.svg";
import Logo_gear from "../../../assets/images/Logo_gear.svg";
import Logo_robot_darkmode from "../../../assets/images/Logo_robot_darkmode.svg";
import Logo_gear_darkmode from "../../../assets/images/Logo_gear_darkmode.svg";

import classes from "../../../assets/stylesheets/SpinnerContainer.module.scss";

const SpinnerContainer = ({ props }) => {
	const gearRef = useRef();

	useEffect(() => {
		const elem = document.querySelector(`#${props.elemId}`);

		const gearElem = gearRef.current;

		const hoverHandler = () => gearElem.classList.add("play");
		const leaveHandler = () => gearElem.classList.remove("play");

		elem.addEventListener("mouseenter", hoverHandler);
		elem.addEventListener("mouseleave", leaveHandler);

		return () => {
			elem.removeEventListener("mouseenter", hoverHandler);
			elem.removeEventListener("mouseleave", leaveHandler);
		};
	});

	return (
		<div className={classes.Spinner_Container}>
			<div className={classes.Robo_Body}>
				{props.isDarkMode ? (
					<img src={Logo_robot_darkmode} className="w-100" alt="robot" />
				) : (
					<img src={Logo_robot} className="w-100" alt="robot" />
				)}
			</div>
			<div className={classes.Gear} ref={gearRef}>
				{props.isDarkMode ? (
					<img src={Logo_gear_darkmode} className="w-100" alt="robot_gear" />
				) : (
					<img src={Logo_gear} className="w-100" alt="robot_gear" />
				)}
			</div>
		</div>
	);
};

export default SpinnerContainer;

SpinnerContainer.propTypes = {
	props: PropTypes.shape({
		elemId: PropTypes.string,
	}),
};
