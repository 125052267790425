import { useState } from "react";

function useTogglerHandler({
	path,
	authenticityToken,
	id,
	intialState,
	new_user_session_path,
	isCurrentUser,
	isAuthor,
	onSuccessHandler = () => {},
}) {
	const [togglerErrorState, changeTogglerErrorState] = useState(false);
	const [togglerState, changeToggleState] = useState(intialState);

	function togglerHandler() {
		if (isCurrentUser && !isAuthor) {
			fetch(path, {
				method: "PATCH",
				headers: {
					"X-CSRF-Token": authenticityToken,
					"X-Requested-With": "XMLHttpRequest",
				},
				body: JSON.stringify({
					id,
					remote: true,
				}),
			})
				.then((res) => {
					if (res.status === 200) {
						changeTogglerErrorState(false);
						changeToggleState(!togglerState);
						onSuccessHandler(togglerState);
						return;
					}
					if (res.status === 401) {
						location.replace(new_user_session_path);
					}
					throw new Error();
				})
				.catch(() => changeTogglerErrorState(true));
		} else if (!isCurrentUser) {
			location.replace(new_user_session_path);
		}
	}
	return {
		data: [togglerState],
		errors: [togglerErrorState, changeTogglerErrorState],
		togglerHandler,
	};
}

export default useTogglerHandler;
