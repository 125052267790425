import React, { useState, useRef } from "react";
import ReportOption from "./ReportOption";

const ReportForm = ({
	action,
	authenticityToken,
	custom_msg_txt,
	report_txt,
	reportTypes,
	otherOption,
	custom_msg_other_option_txt,
}) => {
	const [otherOptionState, changeOtherOptionState] = useState(false);
	const [reportMsgState, changeReportMsgState] = useState("");

	const reportFormRef = useRef(null);

	const reportBtnHandler = () => {
		if (otherOptionState && !reportMsgState) {
			return;
		}
		reportFormRef.current.submit();
	};
	return (
		<form method="post" action={action} ref={reportFormRef}>
			<fieldset className="form-group">
				<div className="row">
					<div className="col-12">
						{reportTypes.map((item, index) => {
							return (
								<ReportOption
									key={index}
									option={item}
									id={index}
									disableOtherOptionHandler={() =>
										otherOptionState ? changeOtherOptionState(false) : null
									}
								/>
							);
						})}
						<div className="form-check mb-2">
							<input
								className="form-check-input"
								type="radio"
								name="reportOption"
								id="option_x"
								value="option_x"
								checked={otherOptionState}
								onChange={() => changeOtherOptionState(true)}
							/>
							<label className="form-check-label" htmlFor="option_x">
								{otherOption}
							</label>
						</div>
					</div>
				</div>
			</fieldset>

			<div className="form-group">
				<label htmlFor="ReportTextArea">
					{otherOptionState ? custom_msg_other_option_txt : custom_msg_txt}
				</label>
				<textarea
					className="form-control"
					id="ReportTextArea"
					name="reportMessage"
					rows="2"
					value={reportMsgState}
					onChange={(e) => changeReportMsgState(e.target.value)}
				></textarea>
			</div>

			<input
				type="hidden"
				name="authenticity_token"
				value={authenticityToken}
			/>
			<div>
				<button
					type="button"
					className="btn btn-danger btn-block"
					disabled={otherOptionState && !reportMsgState}
					onClick={reportBtnHandler}
				>
					{report_txt}
				</button>
			</div>
		</form>
	);
};

export default ReportForm;
