import React from "react";
import PropTypes from "prop-types";

const Dropdown = ({ children, styleClasses }) => {
	return (
		<div className={`${styleClasses ? styleClasses : ""} dropdown`}>
			{children}
		</div>
	);
};

Dropdown.Btn = ({ children, styleClasses }) => {
	return (
		<button
			className={`btn p-0 ${styleClasses ? styleClasses : ""}`}
			id="navbarDropdown"
			role="button"
			data-toggle="dropdown"
			aria-haspopup="true"
			aria-expanded="false"
		>
			{children}
		</button>
	);
};

Dropdown.DropdownMenu = ({ children, styleClasses, dirClasses }) => {
	return (
		<div
			className={`
				${styleClasses ? styleClasses : ""} 
				dropdown-menu position-absolute
                ${dirClasses ? dirClasses : ""}`}
			aria-labelledby="navbarDropdown"
		>
			{children}
		</div>
	);
};

export default Dropdown;

Dropdown.propTypes = {
	styleClasses: PropTypes.string,
	children: PropTypes.array,
};

Dropdown.Btn.propTypes = {
	children: PropTypes.object,
};

Dropdown.DropdownMenu.propTypes = {
	styleClasses: PropTypes.string,
	dir: PropTypes.string,
};
