// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// import 'bootstrap'
require("bootstrap");
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("./file_upload_value");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

ReactRailsUJS.unmountComponents = function() {};

require("trix");
require("@rails/actiontext");

const disableReactDevtools = () => {
	for (const prop in window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
		if (prop === "renderers") {
			window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = new Map();
		} else {
			window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] =
				typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] === "function"
					? () => {}
					: null;
		}
	}
};

const PRODUCTION = "production";

if (process.env.NODE_ENV === PRODUCTION) {
	disableReactDevtools();
}
