import React from "react";

import "../../../assets/stylesheets/Modal.module.scss";

const Modal = ({ children, title }) => {
	return (
		<div
			className="modal fade"
			id="ReportModal"
			tabIndex="-1"
			aria-labelledby="ReportModalTitle"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="ReportModalTitle">
							{title}
						</h5>
						<button
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">{children}</div>
				</div>
			</div>
		</div>
	);
};

export default Modal;
