import React from "react";
import PropTypes from "prop-types";

import classes from "../../../assets/stylesheets/FooterItem.module.scss";

const FooterItem = ({ children, header }) => {
	return (
		<div className={`${classes.footerItem}  mb-5 mb-lg-0`}>
			<div className={classes.header}>{header}</div>
			{children}
		</div>
	);
};

export default FooterItem;

FooterItem.propTypes = {
	header: PropTypes.string,
	children: PropTypes.node.isRequired,
};
