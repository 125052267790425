import React from "react";

import classes from "../../../assets/stylesheets/Chatbot.module.scss";

import LoadingDots from "../ui/LoadingDots";

function ChatbotForm({
	submitHandler,
	messageState,
	isLoadingState,
	changeHandler,
	textAreaRef,
	formRef,
}) {
	return (
		<form ref={formRef} className={classes.Control}>
			<textarea
				rows="1"
				type="text"
				placeholder="Hello world"
				value={messageState}
				onChange={(e) => changeHandler(e)}
				ref={textAreaRef}
			></textarea>
			<button type="button" onClick={submitHandler}>
				{!isLoadingState ? (
					<i className="far fa-paper-plane"></i>
				) : (
					<LoadingDots />
				)}
			</button>
		</form>
	);
}

export default ChatbotForm;
