import React, { useState, useRef, useEffect } from "react";
import useTogglerHandler from "../custom_hooks/useTogglerHandler";

import PropTypes from "prop-types";
import Prism from "prismjs";
import detectLang from "../../utils/detectLanguage";

import useDarkMode from "use-dark-mode";

import YTPlayer from "yt-player";

import classes from "../../../assets/stylesheets/ArticleShow.module.scss";

import ArticleInfoShowClasses from "../../../assets/stylesheets/ArticleInfoShow.module.scss";
import ArticleControlShowClasses from "../../../assets/stylesheets/ArticleControlShow.module.scss";

import extractVideoId from "../../utils/extractVideoId";

import ArticleInfo from "../article_index/ArticleInfo";
import ReportForm from "../Forms/ReportForm";
import Modal from "../Modal/Modal";
import ArticleShowControl from "./ArticleShowControl";
import Navbar from "../navbar/Navbar";
import Dropdown from "../navbar/Dropdown";
import CodeBlockStyles from "../../../assets/stylesheets/PrismSnippet.module.scss";

const ArticleShow = ({ props }) => {
	let {
		dir,
		home_txt,
		articleDir,
		articlePath,
		articleUrl,
		articleViews,
		articleLocalLang,
		isUserAvatar,
		avatarDetails,
		authorDetails,
		articleDate,
		articleContent,
		articleReport,
		authenticityToken,
		authorBio,
		authorLink,
		shareUsingFacebook,
		shareUsingTwitter,
		shareUsingEmail,
		shareUsingLinkedin,
		something_went_wrong,
		isCurrentUser,
		likePath,
		likesNum,
		isLike,
		favPath,
		isFav,
		articleId,
		isAuthor,
		new_user_session_path,
		custom_msg_txt,
		custom_msg_other_option_txt,
		report_txt,
		reportTypes,
		otherOption,
		newArticlePath,
		editArticlePath,
		newArticleTxt,
		editArticleTxt,
		deleteArticleTxt,
		edit_profile_txt,
		login_txt,
		logout_txt,
		signup_txt,
		write_now_txt,
		contact_us_txt,
		contact_us_path,
		link_to_other_lang_first_link,
		link_to_other_lang_first_lang,
		link_to_other_lang_last_link,
		link_to_other_lang_last_lang,
		user_signed_in,
		root_p,
		home_path,
		edit_user_registration_path,
		destroy_user_session_path,
		new_user_registration_path,
		write_article_authorized_user,
		write_article_path,
		write_with_us_txt,
	} = props;

	const [likesNumberState, changeLikesNumberState] = useState(likesNum);
	const {
		data: [likeState],
		errors: [likeErrorState, changeLikeErrorState],
		togglerHandler: likeTogglerHandler,
	} = useTogglerHandler({
		path: likePath,
		id: `like-link-${articleId}`,
		intialState: isLike,
		authenticityToken,
		new_user_session_path,
		isCurrentUser,
		isAuthor,
		onSuccessHandler: (likeState) => {
			likeState
				? reduceLikesNumber(likesNumberState)
				: incLikesNumber(likesNumberState);
		},
	});

	const {
		data: [favState],
		errors: [favErrorState, changeFavErrorState],
		togglerHandler: favTogglerHandler,
	} = useTogglerHandler({
		path: favPath,
		id: `toggle-favorite-link-${articleId}`,
		intialState: isFav,
		authenticityToken,
		new_user_session_path,
		isCurrentUser,
		isAuthor,
	});

	const reduceLikesNumber = (oldLikeNumbers) => {
		let updatedLikeNumbers = oldLikeNumbers ? oldLikeNumbers - 1 : 0;
		changeLikesNumberState(updatedLikeNumbers);
	};

	const incLikesNumber = (oldLikeNumbers) => {
		let updatedLikeNumbers = oldLikeNumbers + 1;
		changeLikesNumberState(updatedLikeNumbers);
	};

	const trixRef = useRef();
	useEffect(() => {
		const parser = new DOMParser();
		const doc = parser.parseFromString(articleContent, "text/html");
		const preElems = doc.body.querySelectorAll("pre");
		preElems.forEach((preElem) => {
			if (preElem) {
				let codeBlock = preElem.innerHTML;
				const lang = detectLang(preElem.textContent);
				const updatedPreElem = document.createElement("div");
				const updatedCodeBlock = codeBlock.includes("\n")
					? `
						<div class="${CodeBlockStyles.PrismSnippet}">
							<div class="${CodeBlockStyles.PrismToolbar}">
								<span class="${CodeBlockStyles.Close}"></span>
								<span class="${CodeBlockStyles.Min}"></span>
								<span class="${CodeBlockStyles.Max}"></span>
							</div>
							<pre class="language-${lang} ${CodeBlockStyles.Pre}" data-prismjs-copy="copy"
							data-prismjs-copy-error="failed to copy"
							data-prismjs-copy-success="copied!">
							<code class="line-numbers inline-color language-${lang}">
	
							${codeBlock}

							</code>
							</pre>
						</div>
					`
					: `<div class="${CodeBlockStyles.PrismSnippet} ${CodeBlockStyles.Single}">
					<pre class="language-${lang}">
						<code class="inline-color language-${lang}">${codeBlock}</code>
					</pre>
				</div>`;
				updatedPreElem.innerHTML = updatedCodeBlock;
				preElem.outerHTML = updatedPreElem.innerHTML;
			}
		});
		const content = doc.body.querySelector(".trix-content").innerHTML;
		trixRef.current.innerHTML = content;
	}, []);

	useEffect(() => {
		Prism.plugins.NormalizeWhitespace.setDefaults({
			"remove-trailing": true,
			"remove-indent": true,
			"left-trim": true,
			"right-trim": true,
			"break-lines": 80,
		});
		Prism.highlightAll();
	}, []);

	const favHandler = () => {
		favTogglerHandler();
	};

	const likeHandler = () => {
		likeTogglerHandler();
	};

	function createInstance({ videoId, link }) {
		const videoContainer = document.createElement("div");
		videoContainer.classList.add("video-container");
		const videoWrapper = document.createElement("div");
		videoContainer.append(videoWrapper);
		link.parentNode.replaceChild(videoContainer, link);
		const player = new YTPlayer(videoWrapper);
		player.load(videoId);
	}

	useEffect(() => {
		const links = document.querySelectorAll("a");
		const linksArr = Array.from(links);

		linksArr.map((link) => {
			if (link.href) {
				const videoId = extractVideoId(link.href);
				if (videoId) {
					createInstance({ videoId, link });
				}
			}
		});
	}, []);

	const [hideControlsState, setHideControlState] = useState(false);
	const articleShowRef = useRef(null);
	const articleControlRef = useRef(null);
	const articleShowWrapperWidthOptions = {
		narrow: 800,
		normal: 900,
		wide: "100%",
	};
	const [articleShowWrapperWidthState, changeArticleShowWrapperWidthState] =
		useState(articleShowWrapperWidthOptions.normal);

	const articleShowFontSizeOptions = {
		small: 16,
		medium: 18,
		large: 20,
	};
	const [fontSizeState, changeFontSizeState] = useState(
		articleShowFontSizeOptions.small
	);

	const darkMode = useDarkMode(false, {
		classNameDark: "DarkMode",
		classNameLight: "LightMode",
	});

	const savePreferences = (newPref) => {
		let showPreferences = JSON.parse(localStorage.getItem("showPreferences"));
		if (!showPreferences) {
			showPreferences = {};
		}
		const updatedShowPreferences = { ...showPreferences, ...newPref };
		localStorage.setItem(
			"showPreferences",
			JSON.stringify(updatedShowPreferences)
		);
	};

	const updateFontSize = (option) => {
		changeFontSizeState(option);
		savePreferences({ fontSize: option });
	};

	const updateDarkMode = (option) => {
		option ? darkMode.enable() : darkMode.disable();
	};

	const updateReaderWidth = (option) => {
		changeArticleShowWrapperWidthState(option);
		savePreferences({ readerWidth: option });
	};

	useEffect(() => {
		const userPreferences = JSON.parse(localStorage.getItem("showPreferences"));
		if (userPreferences) {
			changeFontSizeState(
				userPreferences.fontSize || articleShowFontSizeOptions.small
			);
			changeArticleShowWrapperWidthState(
				userPreferences.readerWidth || articleShowWrapperWidthOptions.normal
			);
		}
	}, []);

	useEffect(() => {
		const toggleAppearance = () => {
			setHideControlState(
				window.pageYOffset + 150 >= articleShowRef.current.offsetHeight
			);
		};
		const makeStickyControl = () => {
			if (window.pageYOffset >= articleShowRef.current.offsetTop) {
				articleControlRef.current.classList.add(
					ArticleControlShowClasses.Sticky
				);
			} else {
				articleControlRef.current.classList.remove(
					ArticleControlShowClasses.Sticky
				);
			}
		};
		const handleControlAppearance = () => {
			toggleAppearance();
			makeStickyControl();
		};
		window.addEventListener("scroll", handleControlAppearance);
		return () => window.removeEventListener("scroll", handleControlAppearance);
	}, []);

	return (
		<section>
			<Navbar
				props={{
					dir,
					reverse: false,
					home_txt,
					edit_profile_txt,
					login_txt,
					logout_txt,
					signup_txt,
					write_now_txt,
					write_with_us_txt,
					contact_us_txt,
					contact_us_path,
					link_to_other_lang_first_link,
					link_to_other_lang_first_lang,
					link_to_other_lang_last_link,
					link_to_other_lang_last_lang,
					user_signed_in,
					root_p,
					home_path,
					edit_user_registration_path,
					new_user_session_path,
					destroy_user_session_path,
					new_user_registration_path,
					write_article_authorized_user,
					write_article_path,
					authenticityToken,
					isDarkMode: darkMode.value,
				}}
			/>

			<div className="container">
				{likeErrorState || favErrorState ? (
					<div className="alert alert-danger alert-dismissible" role="alert">
						{something_went_wrong}
						<button
							type="button"
							className="close"
							onClick={() => {
								if (likeErrorState) {
									changeLikeErrorState(false);
								}
								if (favErrorState) {
									changeFavErrorState(false);
								}
							}}
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				) : null}

				{isAuthor ? (
					<div className="dropdown d-flex justify-content-end">
						<Dropdown>
							<Dropdown.Btn>
								<i className="fas fa-ellipsis-h" style={{ fontSize: 25 }}></i>
							</Dropdown.Btn>
							<Dropdown.DropdownMenu dirClasses="dropdown-menu-right">
								<a href={newArticlePath} className="dropdown-item my-2">
									{newArticleTxt}
								</a>
								<a href={editArticlePath} className="dropdown-item my-2">
									{editArticleTxt}
								</a>
								<a
									data-confirm="Are you sure?"
									data-method="delete"
									href={articlePath}
									rel="nofollow"
									className="dropdown-item my-2 text-danger"
								>
									{deleteArticleTxt}
								</a>
							</Dropdown.DropdownMenu>
						</Dropdown>
					</div>
				) : null}
				<div className="d-flex justify-content-between">
					<div ref={articleControlRef}>
						<ArticleShowControl
							hideControlsState={hideControlsState}
							ArticleControlShowClasses={ArticleControlShowClasses}
							likeHandler={likeHandler}
							isAuthor={isAuthor}
							likeState={likeState}
							likesNumberState={likesNumberState}
							favHandler={favHandler}
							favState={favState}
							articleViews={articleViews}
							dir={dir}
							articleLocalLang={articleLocalLang}
							articleUrl={articleUrl}
							articleShowWrapperWidthState={articleShowWrapperWidthState}
							articleShowWrapperWidthOptions={articleShowWrapperWidthOptions}
							darkMode={darkMode}
							articleShowFontSizeOptions={articleShowFontSizeOptions}
							fontSizeState={fontSizeState}
							updateFontSize={updateFontSize}
							updateDarkMode={updateDarkMode}
							updateReaderWidth={updateReaderWidth}
							shareUsingFacebook={shareUsingFacebook}
							shareUsingEmail={shareUsingEmail}
							shareUsingLinkedin={shareUsingLinkedin}
							shareUsingTwitter={shareUsingTwitter}
						/>
					</div>

					<div
						className={`${classes.ArticleShow} trix-content`}
						ref={articleShowRef}
						style={{ fontSize: fontSizeState }}
					>
						<div
							className="mx-auto"
							style={{ maxWidth: articleShowWrapperWidthState }}
						>
							<ArticleInfo
								show={true}
								styleClassName={ArticleInfoShowClasses}
								articleDir={articleDir}
								articleDate={articleDate}
								isAuthorImg={isUserAvatar}
								authorName={authorDetails}
								authorBio={authorBio}
								articleTitle={articleLocalLang}
								authorImg={avatarDetails}
								articlePath={articlePath}
								articleViews={articleViews}
								authorLink={authorLink}
							/>
							<div className={classes.ArticleContentWrapper} dir={articleDir}>
								<h2 className={classes.ArticleTitle}>{articleLocalLang}</h2>

								<div className={classes.ArticleContent} ref={trixRef}></div>
							</div>
						</div>
					</div>
				</div>

				<Modal title="Report Article">
					<ReportForm
						reportTypes={reportTypes}
						action={articleReport}
						authenticityToken={authenticityToken}
						custom_msg_txt={custom_msg_txt}
						custom_msg_other_option_txt={custom_msg_other_option_txt}
						report_txt={report_txt}
						otherOption={otherOption}
					/>
				</Modal>
			</div>
		</section>
	);
};

export default ArticleShow;

ArticleShow.propTypes = {
	dir: PropTypes.string,
	isAuthor: PropTypes.bool,
	isUserAvatar: PropTypes.bool,
	articleDir: PropTypes.string,
	newArticlePath: PropTypes.string,
	editArticlePath: PropTypes.string,
	articlePath: PropTypes.string,
	articleUrl: PropTypes.string,
	articleViews: PropTypes.number,
	articleLocalLang: PropTypes.string,
	avatarDetails: PropTypes.string,
	authorDetails: PropTypes.string,
	articleDate: PropTypes.string,
	articleContent: PropTypes.string,
	articleReport: PropTypes.string,
	authenticityToken: PropTypes.string,
	authorBio: PropTypes.string,
};
