import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import classes from "../../../assets/stylesheets/ArticleItem.module.scss";
import ArticleImgClasses from "../../../assets/stylesheets/ArticleImgContainer.module.scss";
import ArticleInfoClasses from "../../../assets/stylesheets/ArticleInfo.module.scss";
import ArticleControlClasses from "../../../assets/stylesheets/ArticleControl.module.scss";

import PlaceholderImgLg from "../../../assets/images/articles/placeholder_bg_lg.png";

import PlaceholderImgSm from "../../../assets/images/articles/placeholder_bg_sm.png";

import ArticleControl from "./ArticleControl";
import ArticleHero from "./ArticleHero";
import ArticleInfo from "./ArticleInfo";
import ArticleHzLine from "../ui/ArticlesHzLine";

import strTruncation from "../../utils/strTruncation";

const ArticleItem = ({ props }) => {
	const {
		isAuthorImg,
		authorImg,
		authorName,
		isArticleText,
		articleDir,
		articleTitle,
		articlePath,
		articleDate,
		articleImg,
		articleViews,
		authorBio,
		authorLink,
		articleContent,
	} = props;

	const [isLgScreenState, changeIsLgScreenState] = useState(
		window.matchMedia("(min-width: 450px)").matches
	);

	const updateMedia = () => {
		if (window.innerWidth > 450) {
			changeIsLgScreenState(true);
		} else {
			changeIsLgScreenState(false);
		}
	};

	useEffect(() => {
		window.addEventListener("resize", updateMedia);
		return () => window.removeEventListener("resize", updateMedia);
	}, []);

	const [imgUrl, changeImgUrl] = useState(null);

	useEffect(() => {
		const parser = new DOMParser();
		const doc = parser.parseFromString(articleContent, "text/html");
		const firstImg = doc.body.querySelector(".trix-content img");
		if (firstImg && !imgUrl) {
			changeImgUrl(firstImg.getAttribute("src"));
		}
	}, []);

	return (
		<div className={classes.ArticleItem} dir={articleDir}>
			<ArticleHero
				styleClassName={ArticleImgClasses}
				articleDir={articleDir}
				articleTitle={articleTitle}
				articlePath={articlePath}
				isArticleText={isArticleText}
			>
				<a
					href={articlePath}
					className={ArticleImgClasses.ArticleImg}
					style={{
						backgroundImage: imgUrl
							? `url("${imgUrl}")`
							: `url("${
									isLgScreenState ? PlaceholderImgLg : PlaceholderImgSm
							  }")`,
					}}
				></a>
			</ArticleHero>
			<ArticleInfo
				styleClassName={ArticleInfoClasses}
				articleDir={articleDir}
				articleDate={articleDate}
				isAuthorImg={isAuthorImg}
				authorName={authorName}
				articleTitle={articleTitle}
				authorImg={authorImg}
				articlePath={articlePath}
				articleViews={articleViews}
				authorBio={authorBio}
				authorLink={authorLink}
			>
				<div className={ArticleInfoClasses.ArticleNameWrapper}>
					<a className={ArticleInfoClasses.ArticleName} href={articlePath}>
						{strTruncation({ str: articleTitle })}
					</a>
				</div>
			</ArticleInfo>
		</div>
	);
};

export default ArticleItem;

ArticleItem.propTypes = {
	isAuthorImg: PropTypes.bool,
	isArticleText: PropTypes.bool,
	authorImg: PropTypes.string,
	authorName: PropTypes.string,
	articleDir: PropTypes.string,
	articleTitle: PropTypes.string,
	articlePath: PropTypes.string,
	articleDate: PropTypes.string,
	articleImg: PropTypes.string,
	articleViews: PropTypes.string,
};
