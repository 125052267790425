import React from "react";
import useDimensions from "react-use-dimensions";
import "animate.css";

import classes from "../../../assets/stylesheets/Landing.module.scss";

import Animated1 from "../../../assets/images/landing/hero/animated/animate1.png";
import Animated2 from "../../../assets/images/landing/hero/animated/animate2.png";
import Animated3 from "../../../assets/images/landing/hero/animated/animate3.png";
import Overlay from "../../../assets/images/landing/hero/animated/animate4.png";

import Scientist from "../../../assets/images/landing/hero/scientist.png";
import Robot from "../../../assets/images/landing/hero/robot.png";
import Shadow from "../../../assets/images/landing/hero/shadow.png";
import Platform from "../../../assets/images/landing/hero/container.png";
import HeroImg from "../../../assets/images/landing/hero/heroImg.png";
import Cloud from "../../../assets/images/landing/hero/cloud.png";

import Curve from "../../../assets/images/landing/hero/curve.svg";
import CurveSM from "../../../assets/images/landing/hero/curveSM.svg";

import Navbar from "../../components/navbar/Navbar";
import SubscribeForm from "../footer/SubscribeForm";

const Landing = ({
	props: {
		dir,
		home_txt,
		edit_profile_txt,
		login_txt,
		logout_txt,
		signup_txt,
		write_now_txt,
		contact_us_txt,
		contact_us_path,
		link_to_other_lang_first_link,
		link_to_other_lang_first_lang,
		link_to_other_lang_last_link,
		link_to_other_lang_last_lang,
		user_signed_in,
		root_p,
		home_path,
		edit_user_registration_path,
		new_user_session_path,
		destroy_user_session_path,
		new_user_registration_path,
		write_article_authorized_user,
		write_article_path,
		authenticityToken,
		headLine1,
		strikeLine,
		headLine2,
		get_in_touch_msg_txt,
		subscribe_txt,
		email_txt,
		write_with_us_txt,
	} = props,
}) => {
	const [navbarLgRef, navbarLgDims] = useDimensions();
	const [navbarSmRef, navbarSmDims] = useDimensions();

	return (
		<section
			className="position-relative"
			style={{ backgroundColor: "#ffffff" }}
		>
			<div
				className={classes.Overlay}
				style={{ backgroundImage: `url("${Overlay}")` }}
			></div>
			<div
				className={classes.OverlayTwo}
				style={{ backgroundImage: `url("${Overlay}")` }}
			></div>

			<div className="container position-relative d-sm-none">
				<img
					src={CurveSM}
					style={{
						width: `calc((100% - ${navbarSmDims.width}px - 15px) + ((100vw - 100%) / 2))`,
					}}
					className={classes.CurveSM}
					alt="curve_sm"
				/>
			</div>

			<div className="container position-relative d-none d-sm-block d-lg-none">
				<img
					src={Curve}
					style={{
						width: `calc((100% - ${navbarSmDims.width}px) + ((100vw - 100%) / 2))`,
					}}
					className={classes.CurveSM}
					alt="curve_md"
				/>
			</div>

			<div className="container position-relative d-none d-lg-block">
				<img
					src={Curve}
					style={{
						width: `calc((100% - ${navbarLgDims.width}px) + ((100vw - 100%) / 2))`,
					}}
					className={classes.Curve}
					alt="curve_big"
				/>
			</div>

			<div className={classes.CurveBackdrop}></div>

			<Navbar
				props={{
					dir,
					reverse: true,
					home_txt,
					edit_profile_txt,
					login_txt,
					logout_txt,
					signup_txt,
					write_now_txt,
					write_with_us_txt,
					contact_us_txt,
					contact_us_path,
					link_to_other_lang_first_link,
					link_to_other_lang_first_lang,
					link_to_other_lang_last_link,
					link_to_other_lang_last_lang,
					user_signed_in,
					root_p,
					home_path,
					edit_user_registration_path,
					new_user_session_path,
					destroy_user_session_path,
					new_user_registration_path,
					write_article_authorized_user,
					write_article_path,
					authenticityToken,
					navbarLgRef: navbarLgRef,
					navbarSmRef: navbarSmRef,
				}}
			/>

			<div className={classes.Landing}>
				<div className="container">
					<div className="row align-items-center">
						<div className="col-12 col-lg-6">
							<div className={classes.HeroInfo}>
								<h3 className={classes.HeroTitle}>
									{headLine1} <span>{strikeLine}</span> {headLine2}
								</h3>
								<h4 className={classes.HeroSubscribeTxt}>
									{get_in_touch_msg_txt}
								</h4>
								<div className={classes.SubscribeForm}>
									<SubscribeForm
										subscribe_txt={subscribe_txt}
										email_txt={email_txt}
									/>
								</div>
							</div>
						</div>
						<div className="col-12 d-lg-none">
							<div className={classes.HeroImgStyle}>
								<img className="img-fluid" src={HeroImg} alt="hero" />
							</div>
						</div>
						<div className="d-none d-lg-flex col-lg-6">
							<div className={`d-flex w-100 ${classes.LabContainer}`}>
								<div className={`${classes.RobotContainer} w-50`}>
									<img
										src={Robot}
										alt="robot-body"
										className={`${classes.RobotBody} animate__animated animate__fadeInRight animate__fast`}
									/>
									<img
										src={Platform}
										alt="platform"
										className="animate__animated animate__fadeInUp animate__fast"
									/>
								</div>
								<div className={`${classes.ScientistContainer} w-50`}>
									<img
										src={Scientist}
										alt="scientist"
										className="animate__animated animate__fadeInRight animate__fast"
									/>
								</div>
								<div className={classes.Shadow}>
									<img
										src={Shadow}
										alt="shadow"
										className="animate__animated animate__fadeInUp animate__fast"
									/>
								</div>
							</div>
						</div>
					</div>
					<div className={classes.Cloud}>
						<img src={Cloud} alt="cloud" />
						<div className={classes.CloudBackdrop}></div>
					</div>
					<div className={classes.AnimatedBubles}>
						<img src={Animated1} alt="buble1" />
						<img src={Animated2} alt="buble2" />
						<img src={Animated3} alt="buble3" />
					</div>
				</div>
			</div>
		</section>
	);
};

export default Landing;
