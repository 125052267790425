import React from "react";
import PropTypes from "prop-types";

import classes from "../../../assets/stylesheets/Brand.module.scss";

import SpinnerContainer from "../ui/SpinnerContainer";

const Brand = ({ root_p, isDarkMode }) => {
	return (
		<a className={`${classes.Brand} navbar-brand`} href={root_p} id="brand">
			<SpinnerContainer props={{ elemId: "brand", isDarkMode: isDarkMode }} />
		</a>
	);
};

export default Brand;

Brand.propTypes = {
	root_p: PropTypes.string,
};
