import React from "react";
import Robot from "../../../assets/images/chatbot/robot.png";
import classes from "../../../assets/stylesheets/Chatbot.module.scss";

function ChatbodyPlaceholder({ shown }) {
	let output = null;
	if (shown) {
		output = (
			<div className={classes.Placeholder}>
				<img src={Robot} alt="robot" />
				<p className="text-center mt-5">Hi there, wanna chat!</p>
			</div>
		);
	}
	return output;
}

export default ChatbodyPlaceholder;
