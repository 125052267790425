import React from "react";
import CodeBlockStyles from "../../../assets/stylesheets/PrismSnippet.module.scss";

function ChatCodeBlock({ code, lang }) {
	return (
		<div className={`${CodeBlockStyles.PrismSnippet}`}>
			<div className={`${CodeBlockStyles.PrismToolbar}`}>
				<span className={`${CodeBlockStyles.Close}`}></span>
				<span className={`${CodeBlockStyles.Min}`}></span>
				<span className={`${CodeBlockStyles.Max}`}></span>
			</div>
			<pre
				className={`${CodeBlockStyles.Pre} language-${lang}`}
				data-prismjs-copy="copy"
				data-prismjs-copy-error="failed to copy"
				data-prismjs-copy-success="copied!"
			>
				<code className={`language-${lang} line-numbers inline-color`}>
					<script type="text/plain" className="language-markup"></script>
					{code}
				</code>
			</pre>
		</div>
	);
}
export default ChatCodeBlock;
