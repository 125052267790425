import React from "react";

import ArticleInfo from "../article_index/ArticleInfo";
import ArticleControl from "../article_index/ArticleControl";
import ArticleShowClasses from "../../../assets/stylesheets/ArticleShow.module.scss";
import ArticleInfoShowClasses from "../../../assets/stylesheets/ArticleInfoShow.module.scss";
import ArticleControlShowClasses from "../../../assets/stylesheets/ArticleControlShow.module.scss";
import ArticleControlProfileClasses from "../../../assets/stylesheets/ArticleControlProfile.module.scss";
import classes from "../../../assets/stylesheets/ProfileContent.module.scss";

const Profile = ({ props }) => {
	let {
		dir,
		isAuthorImg,
		authorImg,
		authorName,
		authorBio,
		joinTime,
		recentArticlesHeaderTranslated,
		socialMediaLinks,
	} = props;
	const socialMediaIcons = {
		facebook: <i className="fab fa-facebook-f"></i>,
		email: <i className="far fa-envelope"></i>,
		linkedin: <i className="fab fa-linkedin-in"></i>,
		github: <i className="fab fa-github"></i>,
	};
	let linksContent = [];
	if (Array.isArray(socialMediaLinks)) {
		socialMediaLinks.map((elem) => {
			linksContent.push(<a href={elem.url}>{socialMediaIcons[elem.name]}</a>);
		});
	}
	return (
		<div className="d-flex justify-content-between">
			<ArticleControl styleClassName={ArticleControlProfileClasses}>
				<button
					onClick={() => window.history.back()}
					className={`btn ${ArticleControlShowClasses.BackBtn}`}
				>
					<i className="fas fa-arrow-left"></i>
				</button>
			</ArticleControl>

			<div className={`${ArticleShowClasses.ArticleShow} ${classes.Profile}`}>
				<div className="w-100" style={{ maxWidth: "900px" }}>
					<ArticleInfo
						styleClassName={ArticleInfoShowClasses}
						show={true}
						articleDir={dir}
						articleDate={joinTime}
						isAuthorImg={isAuthorImg}
						authorName={authorName}
						authorBio={authorBio}
						authorImg={authorImg}
					/>
					<div className={classes.ProfileContent}>
						<h3 className={classes.ProfileContentHeader}>
							{recentArticlesHeaderTranslated}
						</h3>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Profile;
