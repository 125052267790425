import React from "react";

import classes from "../../../assets/stylesheets/UserDashboardControls.module.scss";

import PersonIcon from "../../../assets/images/user_dashboard/person.svg";
import SettingsIcon from "../../../assets/images/user_dashboard/settings.svg";
import ArticlesIcon from "../../../assets/images/user_dashboard/articles.svg";
import AddNewIcon from "../../../assets/images/user_dashboard/new.svg";
import FavouriteIcon from "../../../assets/images/user_dashboard/favourites.svg";
import FooterListItem from "./FooterListItem";

const Footer = ({ props }) => {
	const {
		isAuthor,
		isPrivate,
		articles_path,
		edit_user_registration_path,
		fav_path,
	} = props;

	let content;

	if (isPrivate && isAuthor) {
		content = (
			<>
				<FooterListItem
					Icon={FavouriteIcon}
					iconAlt="favourites"
					label="Favourites"
					path={fav_path}
				/>
				<FooterListItem
					Icon={ArticlesIcon}
					iconAlt="articles"
					label="Articles"
					path={articles_path}
				/>

				<li className={classes.addNew}>
					<a href="/articles/new">
						<img src={AddNewIcon} />
					</a>
				</li>

				<FooterListItem
					Icon={PersonIcon}
					iconAlt="person"
					label="My Accounts"
					path="#"
				/>

				<FooterListItem
					Icon={SettingsIcon}
					iconAlt="settings"
					label="Settings"
					path={edit_user_registration_path}
				/>
			</>
		);
	} else if (isPrivate && !isAuthor) {
		content = (
			<>
				<FooterListItem
					Icon={PersonIcon}
					iconAlt="person"
					label="My Accounts"
					path="#"
				/>

				<FooterListItem
					Icon={SettingsIcon}
					iconAlt="settings"
					label="Settings"
					path={edit_user_registration_path}
				/>
			</>
		);
	} else {
		content = (
			<FooterListItem
				Icon={ArticlesIcon}
				iconAlt="articles"
				label="Articles"
				path={articles_path}
			/>
		);
	}

	return (
		<div className={classes.userDashboardFooter}>
			<div className="container px-xl-0">
				<ul
					className={`
						${classes.userDashboardList} 
						${!isPrivate ? classes.userDashboardListCentered : ""}
					`}
				>
					{content}
				</ul>
			</div>
		</div>
	);
};

export default Footer;
