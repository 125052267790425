function ErrorAleart({ isError, errorMessage, changeIsError }) {
	return isError ? (
		<div className="alert alert-danger alert-dismissible" role="alert">
			{errorMessage}
			<button
				type="button"
				className="close"
				onClick={() => {
					changeIsError(false);
				}}
			>
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
	) : null;
}

export default ErrorAleart;
