import React from "react";

import classes from "../../../assets/stylesheets/UserDashboardControls.module.scss";

const FooterListItem = ({ Icon, iconAlt, label, path }) => {
	const currentPath = location.pathname;
	return (
		<li>
			<a
				href={path}
				className={`${currentPath === path ? classes.active : " "}`}
			>
				<img src={Icon} alt={iconAlt} width="30" height="30" />
				<span>{label}</span>
			</a>
		</li>
	);
};

export default FooterListItem;
