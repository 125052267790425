import React from "react";
import PropTypes from "prop-types";

import classes from "../../../assets/stylesheets/Backdrop.module.scss";

const Backdrop = ({ isHidden }) => {
	return !isHidden ? <div className={classes.Backdrop}></div> : null;
};

export default Backdrop;

Backdrop.propTypes = {
	isHidden: PropTypes.bool,
};
