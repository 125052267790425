import detectLang from "lang-detector";

function detectLanguage(code) {
	const langNameToClassName = {
		"c++": "cpp",
	};
	const lang = detectLang(code);
	detectLang("");
	let lowerLang = lang.toLowerCase();
	if (lowerLang == "c++") {
		lowerLang = langNameToClassName[lowerLang];
	}

	return lowerLang;
}
export default detectLanguage;
