import React from "react";

const SettingModalItem = ({
	inputId,
	labelVal,
	isChecked,
	changeIsChecked,
}) => {
	return (
		<div className="form-check mb-3">
			<input
				type="checkbox"
				className="form-check-input"
				id={inputId}
				checked={isChecked}
				onChange={() => changeIsChecked(!isChecked)}
			/>
			<label className="form-check-label" htmlFor={inputId}>
				{labelVal}
			</label>
		</div>
	);
};

export default SettingModalItem;
