import React from "react";
import PropTypes from "prop-types";

import Dropdown from "./Dropdown";
import ButtonsControl from "./ButtonsControl";

const NavList = ({
	dir,
	styleClasses,
	isDesktopState,
	home_path,
	home_txt,
	edit_profile_txt,
	write_now_txt,
	contact_us_txt,
	user_signed_in,
	edit_user_registration_path,
	write_article_authorized_user,
	write_article_path,
	contact_us_path,
	root_path,
	new_user_session_path,
	login_txt,
	new_user_registration_path,
	signup_txt,
	destroy_user_session_path,
	logout_txt,
	write_with_us_txt,
	authenticityToken,
	navbarSmRef,
}) => {
	const writeWithUsHandler = (e) => {
		e.preventDefault();
		window.location.href = contact_us_path + "?auto_write=true";
	};

	let navListContent = (
		<ul className="navbar-nav d-flex flex-row">
			<li className="nav-item active mx-2 mx-md-3">
				<a className="nav-link" href={home_path}>
					<i className="fas fa-home"></i>
					<span className="sr-only">(current)</span>
				</a>
			</li>
			{write_article_authorized_user ? (
				<>
					<li className="nav-item mx-2 mx-md-3">
						<a className="nav-link" href={write_article_path}>
							<i className="fas fa-feather-alt"></i>
						</a>
					</li>
					<li className="nav-item mx-2 mx-md-3">
						<a className="nav-link" href="/chatbot">
							<i className="fas fa-robot"></i>
						</a>
					</li>
				</>
			) : null}

			<li
				className="nav-item mx-2 mx-md-3 d-flex align-items-center"
				ref={navbarSmRef}
			>
				<Dropdown>
					<Dropdown.Btn>
						<i className={`${styleClasses.ProfileBtn} far fa-user-circle`}></i>
					</Dropdown.Btn>
					<Dropdown.DropdownMenu
						styleClasses={styleClasses.dropdown_menu}
						dirClasses={`${
							dir === "ltr"
								? "dropdown-menu-right"
								: "dropdown-menu-left text-right"
						}`}
					>
						{user_signed_in && (
							<>
								<a className="dropdown-item" href={edit_user_registration_path}>
									{edit_profile_txt}
								</a>

								<div
									className={`${styleClasses.dropdown_divider} dropdown-divider`}
								></div>
							</>
						)}
						<ButtonsControl
							root_path={root_path}
							user_signed_in={user_signed_in}
							new_user_session_path={new_user_session_path}
							login_txt={login_txt}
							new_user_registration_path={new_user_registration_path}
							signup_txt={signup_txt}
							destroy_user_session_path={destroy_user_session_path}
							logout_txt={logout_txt}
							authenticityToken={authenticityToken}
						/>
					</Dropdown.DropdownMenu>
				</Dropdown>
			</li>
		</ul>
	);
	if (isDesktopState) {
		navListContent = (
			<ul className="navbar-nav d-flex">
				<li className="nav-item active">
					<a className="nav-link" href={home_path}>
						{home_txt}
						<span className="sr-only">(current)</span>
					</a>
				</li>
				{write_article_authorized_user ? (
					<>
						<li className="nav-item">
							<a className="nav-link" href={write_article_path}>
								{write_now_txt}
							</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href="/chatbot">
								Chatbot
							</a>
						</li>
					</>
				) : (
					<li className="nav-item">
						<a className="nav-link" href="#" onClick={writeWithUsHandler}>
							{write_with_us_txt}
						</a>
					</li>
				)}

				<li className="nav-item">
					<a className="nav-link" href={contact_us_path}>
						{contact_us_txt}
					</a>
				</li>
				{user_signed_in && (
					<li className="nav-item">
						<a className="nav-link" href={edit_user_registration_path}>
							{edit_profile_txt}
						</a>
					</li>
				)}
			</ul>
		);
	}
	return navListContent;
};

export default NavList;

NavList.propTypes = {
	home_path: PropTypes.string,
	home_txt: PropTypes.string,
	edit_profile_txt: PropTypes.string,
	write_now_txt: PropTypes.string,
	contact_us_txt: PropTypes.string,
	user_signed_in: PropTypes.bool,
	edit_user_registration_path: PropTypes.string,
	write_article_authorized_user: PropTypes.bool,
	write_article_path: PropTypes.string,
	contact_us_path: PropTypes.string,
};
