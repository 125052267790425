const reader = new FileReader();

const fileLoaderHandler = (file) => {
	const preview_img_cont = document.querySelector(".preview_img_cont");
	const previousImg = document.querySelector(".preview_img");
	if (previousImg) {
		previousImg.parentNode.removeChild(previousImg);
	}
	const created_img = document.createElement("img");
	created_img.src = file.target.result;
	created_img.classList.add("rounded-circle", "mb-2", "preview_img");
	preview_img_cont.appendChild(created_img);
};

const changeFileInputHandler = (e) => {
	const file_inp_em = document.querySelector("#file_upload_em");
	if (e && e.target && e.target.files && e.target.files[0]) {
		reader.readAsDataURL(e.target.files[0]);
		const filename = e.target.files[0].name;
		file_inp_em.innerHTML = filename;
	}
};

const fileuploadHandler = () => {
	const file_inp_elem = document.querySelector("#file_upload_input");
	if (file_inp_elem) {
		reader.addEventListener("load", fileLoaderHandler);
		file_inp_elem.addEventListener("change", changeFileInputHandler);
	}
};

document.addEventListener("turbolinks:load", fileuploadHandler);
