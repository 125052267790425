import React from "react";
import PropTypes from "prop-types";

import classes from "../../../assets/stylesheets/FooterList.module.scss";

const FooterList = ({ data }) => {
	return (
		<ul className={`${classes.footerList} nav flex-column`}>
			{data.map((item) => {
				return (
					<li key={item.txt} className="nav-item">
						<a className="nav-link" href={item.url}>
							{item.txt}
						</a>
					</li>
				);
			})}
		</ul>
	);
};

export default FooterList;

FooterList.propTypes = {
	data: PropTypes.array,
	base_url: PropTypes.string,
};
